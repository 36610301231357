import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import loadable from "@loadable/component"

import {
  ADVANTAGES,
  WEB_DEVELOPMENT_SERVICES,
  TITLES,
  SERVICES,
} from "../data/web-development"
import { IMG } from "../const"
import Layout from "../components/layout"
import DesignerAndDev from "../components/tech-stack/designer-developer"
import "../assets/styles/commons/_common-kit.scss"
import * as styles from "../assets/styles/web-development.module.scss"
import Headline from "../components/headline"
import OptionsList from "../components/options-list"
import CostSaving from "../components/web-development/CostSaving"
import ServicesList from "../components/services-list"
import CaseStudies from "../components/case-studies"
import ColoredSection from "../components/colored-section"

const TitleBlock = loadable(() => import("../components/title-block"))
const Accordion = loadable(() => import("../components/accordion"))
const ContactForm = loadable(() => import("../components/contact-form"))

const WebDevelopment = () => {
  return (
    <Layout
      pageTitle="Web Development Service"
      defaultImage={`${IMG.THUMBNAILS}/thumbnail-web-dev.jpg`}
    >
      {({
        width,
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <>
            <main className={cx(styles.webDevelopmentBlock, "common-kit")}>
              <article>
                <Headline>
                  <div className={cx(styles.headline, "headline")}>
                    <div className={cx(styles.headline_grid, "headline_grid")}>
                      <p className={"strongText"}>
                        we bring ideas to life through
                      </p>
                      <h1 className={"withIndent"}>
                        <strong>Custom</strong> web development
                      </h1>
                      <div className={"rightColumn"}>
                        <p>
                          <strong>
                            We are a global web development company with over 85
                            successful projects across 10+ industries.
                          </strong>
                        </p>
                        <p>
                          Since 2014, our professional web design and
                          development teams have built beautiful web solutions
                          that offer long-term advantages to clients' businesses
                          without wasting a fortune.
                        </p>
                        <div className="onDesktop">
                          <Link
                            to="#contact-form"
                            className="goldFatLink customweb-talk"
                          >
                            Let’s talk
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className={styles.flexBlock}>
                      <div className={styles.strongTextBlock}>
                        <h3 className={styles.strongText}>
                          custom web development services are for:
                        </h3>
                      </div>
                      <OptionsList list={WEB_DEVELOPMENT_SERVICES} />
                    </div>

                    <div className="onMobile">
                      <Link
                        to="#contact-form"
                        className="goldFatLink customweb-talk"
                      >
                        Let’s talk
                      </Link>
                    </div>
                  </div>
                </Headline>

                <CostSaving title={TITLES.cost_saving_approach} />

                <section className="section">
                  <TitleBlock options={TITLES.range_services} />
                  <ServicesList services={SERVICES.items} />
                </section>

                <section className={cx(styles.techStack, "section")}>
                  <TitleBlock options={TITLES.tech_stack} />
                  <DesignerAndDev />
                </section>

                <section className={cx(styles.accordionBlock, "section")}>
                  <TitleBlock options={TITLES.advantages} />
                  <div className={styles.accordionWrapper}>
                    <Accordion options={ADVANTAGES} />
                  </div>
                </section>

                <CaseStudies title={TITLES.case_studies} screenWidth={width} />

                <ColoredSection options={TITLES.colored} />
              </article>
            </main>
            <section className="footerForm common-kit">
              <div className="section" id="contact-form">
                <ContactForm
                  formStatID="CommonFooter"
                  handleOpenConfirmation={handleOpenConfirmation}
                  isOpenConfirmation={isOpenConfirmation}
                  setConfirmationOption={setConfirmationOption}
                />
              </div>
            </section>
          </>
        )
      }}
    </Layout>
  )
}

export default WebDevelopment
